import { Injectable } from '@angular/core';

type Origin =
  | 'oppanol'
  | 'glysantin'
  | 'lubricant-components'
  | 'mining-solutions'
  | 'oilfield-chemicals'
  | 'plastic-additives';

const originStorageKey = 'origin';

@Injectable({
  providedIn: 'root',
})
export class OriginService {
  private readonly validOrigins: Origin[] = [
    'oppanol',
    'glysantin',
    'lubricant-components',
    'mining-solutions',
    'oilfield-chemicals',
    'plastic-additives',
  ];

  constructor() {
    const originFromUrl = this.getOriginFromUrl() as Origin | null;
    const originFromLocalStorage = this.getOriginFromLocalStorage();

    const newOrigin = originFromUrl ? originFromUrl : originFromLocalStorage || null;

    if (newOrigin) {
      localStorage.setItem(originStorageKey, newOrigin);
    }
  }

  private getOriginFromUrl(): Origin | null {
    const searchParams = new URL(window.location.href).searchParams;
    const origin = searchParams.get('origin') as Origin | null;

    return origin && this.validOrigins.includes(origin) ? origin : null;
  }

  private getOriginFromLocalStorage(): Origin | null {
    return localStorage.getItem(originStorageKey) as Origin | null;
  }
}
