{
  "dirty": true,
  "raw": "v1.73.0-13-g5951f03b5-dirty",
  "hash": "g5951f03b5",
  "distance": 13,
  "tag": "v1.73.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.73.0",
    "major": 1,
    "minor": 73,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.73.0"
  },
  "suffix": "13-g5951f03b5-dirty",
  "semverString": "1.73.0+13.g5951f03b5.dirty"
}