import { Injectable } from '@angular/core';

type Theme =
  | 'theme-dark-blue'
  | 'theme-light-blue'
  | 'theme-dark-green'
  | 'theme-light-green'
  | 'theme-orange'
  | 'theme-red';

const themeStorageKey = 'theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {
    const themeFromUrl = this.getThemeFromUrl();
    const themeFromLocalStorage = this.getThemeFromLocalStorage();

    const newThemeClass = themeFromUrl
      ? this.getMappedThemeColorClass(themeFromUrl)
      : themeFromLocalStorage || 'theme-dark-blue'; // default theme

    this.applyTheme(newThemeClass, themeFromLocalStorage);
  }

  private getThemeFromUrl(): string | null {
    const searchParams = new URL(window.location.href).searchParams;
    return searchParams.get('theme');
  }

  private getThemeFromLocalStorage(): Theme | null {
    return localStorage.getItem(themeStorageKey) as Theme | null;
  }

  private getMappedThemeColorClass(theme: string | null): Theme {
    switch (theme) {
      case 'darkblue':
        return 'theme-dark-blue';
      case 'lightblue':
        return 'theme-light-blue';
      case 'darkgreen':
        return 'theme-dark-green';
      case 'lightgreen':
        return 'theme-light-green';
      case 'orange':
        return 'theme-orange';
      case 'red':
        return 'theme-red';
      default:
        return 'theme-dark-blue';
    }
  }

  private applyTheme(newThemeClass: Theme, currentThemeClass: Theme | null) {
    if (newThemeClass !== currentThemeClass) {
      if (currentThemeClass) {
        document.body.classList.remove(currentThemeClass);
      }
      localStorage.setItem(themeStorageKey, newThemeClass);
    }
    document.body.classList.add(newThemeClass);
  }
}
