import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { Button } from 'primeng/button';

@Component({
  selector: 'cp-landing-page-header',
  standalone: true,
  imports: [CommonModule, Button, RouterLink],
  templateUrl: './landing-page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageHeaderComponent {
  readonly #navigationService = inject(NavigationService);
  readonly #authenticationService = inject(AuthenticationService);
  readonly user$ = this.#authenticationService.user$;

  readonly exploreProductsLabel = $localize`Explore product portfolio`;
  readonly loginLabel = $localize`Please login to use the portal`;

  navigateToProductsPage(): void {
    this.#navigationService.navigateToProducts();
  }

  navigateToLoginPage(): void {
    this.#navigationService.navigateToLogin();
  }
}
