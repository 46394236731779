import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { AccountRegisterDialogComponent } from '@ev-portals/cp/frontend/shared/feature';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';

@Component({
  standalone: true,
  imports: [CommonModule, AccountRegisterDialogComponent],
  templateUrl: './feature-account.component.html',
  styleUrl: './feature-account.component.scss',
})
export class FeatureAccountComponent {
  title = $localize`Create your BASF account now`;
  subtitle = $localize`Join us to get exclusive access. Download more documents, process your request faster and track the progress!`;
  $origin = signal<string | undefined>(undefined);

  #navigationService = inject(NavigationService);

  constructor() {
    this.#getOriginFromLocalStorage();
  }

  #getOriginFromLocalStorage(): void {
    this.$origin.set(localStorage.getItem('origin') || undefined);
  }

  onClose(): void {
    localStorage.setItem('initial-register-popup-closed', 'true');
    this.#navigationService.navigateToOriginalDestination();
  }
}
